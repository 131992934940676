import React from 'react';
import { Image } from '@chakra-ui/react';
import logo from './logo.svg';
import logo2 from './logo2.svg';
import { useColorModeValue } from '@chakra-ui/react';

export const Logo = props => {
  const text = useColorModeValue(logo2, logo);
  return <Image src={text} {...props} />;
};
