import React from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  Grid,
  theme,
  Text,
  Button,
  Tooltip
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Logo } from './Logo';
import "@fontsource/poppins";
import "./animation.css"

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center">
        <Grid minH="85vh" p={3}>
          <Box />
          <VStack>
            <Logo className="fade-in" style={{"opacity": 0}} h="288" w="422" pointerEvents="none" />
            <VStack spacing={5}>
              <Text className="fade-in" style={{"opacity": 0, "animation-delay": "750ms"}} color="#FDFDFD" fontSize='lg' maxWidth="75%" fontFamily="Poppins" fontWeight="200">Rysk provides vulnerability assessment services to meet your Information Security, Cybersecurity, and Regulatory requirements.</Text>

              <VStack className="fade-in" style={{"opacity": 0, "animation-delay": "1250ms"}}>
                <Tooltip label='contact@rysk.tech' backgroundColor="#FDFDFD" fontFamily="Poppins">
                  <Button color="#FDFDFD" variant='outline' rightIcon={<ArrowForwardIcon />} onClick={() => {window.location.href = "mailto:contact@rysk.tech";}}>
                    <Text color="#FDFDFD" fontFamily="Poppins" fontWeight="200">Contact Us</Text>
                  </Button>
                </Tooltip>
              </VStack>
            </VStack>
          </VStack>
        </Grid>
      </Box>
      <Box textAlign="center" style={{position: "fixed", height: 50, width: "100%", bottom: 0}}>
        <Text fontSize='xs' color="#878787" fontFamily="Poppins">© 2022 - Rysk Technologies, LLC</Text>
      </Box>
    </ChakraProvider>
  );
}

export default App;